import React from "react";
import { bigButton } from "../styles/bigButton";

const TestPage = () => {
  return (
    <div className="w-full flex flex-col gap-5 items-center">
      <nav className="bg-black w-full h-14"></nav>
      <h2 className="font-semibold text-lg text-primary">
        Keep Your Online Presence Active and Secure
      </h2>
      <table className="table-auto border-collapse border border-slate-500 w-1/2">
        <thead>
          <tr>
            <th className="border border-slate-400 p-1">Product Name</th>
            <th class="border border-slate-400 p-1">Expiration date</th>
            <th class="border border-slate-400 p-1">Price:</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border border-slate-500 p-1">
              Domain Privacy for wealthfrontier.org
            </td>
            <td class="border border-slate-500 p-1">
              June 16<sup>th</sup> 2024
            </td>
            <td class="border border-slate-500 p-1">$34.99</td>
          </tr>
          <tr>
            <td class="border border-slate-500 p-1">wealthfrontier.org</td>
            <td class="border border-slate-500 p-1">
              June 16<sup>th</sup> 2024
            </td>
            <td class="border border-slate-500 p-1">$24.99</td>
          </tr>
        </tbody>
      </table>

      <button className={bigButton}>Renew</button>
      <p className="px-10">
        Renewing your domain name is essential to maintaining your online
        presence and ensuring your website remains accessible to your visitors.
        Don't let your hard-earned domain expire—renew it today to protect your
        brand, retain your audience, and continue building your online success.
      </p>
      <h2 className="font-semibold text-lg text-primary text-left w-full px-10">
        Benefits of Renewing Your Domain
      </h2>
      <ul className="list-disc w-full text-left px-10">
        <li>
          Uninterrupted Service: Ensure that your website, email, and other
          services associated with your domain continue to operate smoothly.
        </li>
        <li>
          Brand Protection: Prevent others from registering your domain name,
          safeguarding your brand identity and reputation.
        </li>
        <li>
          SEO Benefits: Maintain your search engine rankings and avoid potential
          traffic loss due to downtime or domain expiration.
        </li>
        <li>
          Customer Trust: Keep your domain active to retain the trust and
          loyalty of your customers and visitors.
        </li>
      </ul>
      <h2 className="font-semibold text-lg text-primary text-left w-full px-10">
        Easy Renewal Process
      </h2>
      <p className="px-10 w-full text-left">
        We’ve made the renewal process quick and straightforward. Simply follow
        these steps:
      </p>
    </div>
  );
};

export default TestPage;
