export const locations = [
  {
    state: "California",
    country: "USA",
    latitude: "36.7783",
    longitude: "-119.4179",
  },
  {
    state: "Texas",
    country: "USA",
    latitude: "31.9686",
    longitude: "-99.9018",
  },
  {
    state: "New York",
    country: "USA",
    latitude: "40.7128",
    longitude: "-74.0060",
  },
  {
    state: "Florida",
    country: "USA",
    latitude: "27.9944",
    longitude: "-81.7603",
  },
  {
    state: "Illinois",
    country: "USA",
    latitude: "40.6331",
    longitude: "-89.3985",
  },
  {
    state: "Pennsylvania",
    country: "USA",
    latitude: "41.2033",
    longitude: "-77.1945",
  },
  { state: "Ohio", country: "USA", latitude: "40.4173", longitude: "-82.9071" },
  {
    state: "Georgia",
    country: "USA",
    latitude: "32.1656",
    longitude: "-82.9001",
  },
  {
    state: "North Carolina",
    country: "USA",
    latitude: "35.7596",
    longitude: "-79.0193",
  },
  {
    state: "Michigan",
    country: "USA",
    latitude: "44.3148",
    longitude: "-85.6024",
  },
  {
    state: "Queensland",
    country: "Australia",
    latitude: "-27.4698",
    longitude: "153.0251",
  },
  {
    state: "Western Australia",
    country: "Australia",
    latitude: "-31.9505",
    longitude: "115.8605",
  },
  {
    state: "South Australia",
    country: "Australia",
    latitude: "-34.9285",
    longitude: "138.6007",
  },
  {
    state: "Tasmania",
    country: "Australia",
    latitude: "-42.8821",
    longitude: "147.3272",
  },
  {
    state: "Northern Territory",
    country: "Australia",
    latitude: "-12.4634",
    longitude: "130.8456",
  },
  {
    state: "Australian Capital Territory",
    country: "Australia",
    latitude: "-35.2809",
    longitude: "149.1300",
  },
  {
    state: "New South Wales",
    country: "Australia",
    latitude: "-33.8688",
    longitude: "151.2093",
  },
  {
    state: "Victoria",
    country: "Australia",
    latitude: "-37.8136",
    longitude: "144.9631",
  },

  {
    state: "Ontario",
    country: "Canada",
    latitude: "51.2538",
    longitude: "-85.3232",
  },
  {
    state: "Quebec",
    country: "Canada",
    latitude: "52.9399",
    longitude: "-73.5491",
  },
  {
    state: "British Columbia",
    country: "Canada",
    latitude: "53.7267",
    longitude: "-127.6476",
  },
  {
    state: "Alberta",
    country: "Canada",
    latitude: "53.9333",
    longitude: "-116.5765",
  },
  {
    state: "Manitoba",
    country: "Canada",
    latitude: "49.8951",
    longitude: "-97.1384",
  },
  {
    state: "Saskatchewan",
    country: "Canada",
    latitude: "52.9399",
    longitude: "-106.4509",
  },
  {
    state: "Nova Scotia",
    country: "Canada",
    latitude: "44.6820",
    longitude: "-63.7443",
  },
  {
    state: "New Brunswick",
    country: "Canada",
    latitude: "46.5653",
    longitude: "-66.4619",
  },
  {
    state: "Newfoundland and Labrador",
    country: "Canada",
    latitude: "53.1355",
    longitude: "-57.6604",
  },
  {
    state: "Prince Edward Island",
    country: "Canada",
    latitude: "46.5107",
    longitude: "-63.4168",
  },
  {
    state: "Karnataka",
    country: "India",
    latitude: "15.3173",
    longitude: "75.7139",
  },
  {
    state: "Gujarat",
    country: "India",
    latitude: "22.2587",
    longitude: "71.1924",
  },
  {
    state: "Rajasthan",
    country: "India",
    latitude: "27.0238",
    longitude: "74.2179",
  },
  {
    state: "Uttar Pradesh",
    country: "India",
    latitude: "26.8467",
    longitude: "80.9462",
  },
  {
    state: "West Bengal",
    country: "India",
    latitude: "22.9868",
    longitude: "87.8550",
  },
  {
    state: "Madhya Pradesh",
    country: "India",
    latitude: "22.9734",
    longitude: "78.6569",
  },
  {
    state: "Haryana",
    country: "India",
    latitude: "29.0588",
    longitude: "76.0856",
  },
  {
    state: "Punjab",
    country: "India",
    latitude: "31.1471",
    longitude: "75.3412",
  },

  {
    state: "Baden-Württemberg",
    country: "Germany",
    latitude: "48.6616",
    longitude: "9.3501",
  },
  {
    state: "Bavaria",
    country: "Germany",
    latitude: "48.7904",
    longitude: "11.4979",
  },
  {
    state: "Berlin",
    country: "Germany",
    latitude: "52.5200",
    longitude: "13.4050",
  },
  {
    state: "Brandenburg",
    country: "Germany",
    latitude: "52.4125",
    longitude: "12.5316",
  },
  {
    state: "Bremen",
    country: "Germany",
    latitude: "53.0793",
    longitude: "8.8017",
  },
  {
    state: "Hamburg",
    country: "Germany",
    latitude: "53.5511",
    longitude: "9.9937",
  },
  {
    state: "Hesse",
    country: "Germany",
    latitude: "50.6521",
    longitude: "9.1624",
  },
  {
    state: "Lower Saxony",
    country: "Germany",
    latitude: "52.6367",
    longitude: "9.8451",
  },
  {
    state: "North Rhine-Westphalia",
    country: "Germany",
    latitude: "51.4332",
    longitude: "7.6616",
  },
  {
    state: "Rhineland-Palatinate",
    country: "Germany",
    latitude: "49.9929",
    longitude: "7.8218",
  },

  {
    state: "Moscow",
    country: "Russia",
    latitude: "55.7558",
    longitude: "37.6173",
  },
  {
    state: "Saint Petersburg",
    country: "Russia",
    latitude: "59.9343",
    longitude: "30.3351",
  },
  {
    state: "Novosibirsk",
    country: "Russia",
    latitude: "55.0084",
    longitude: "82.9357",
  },
  {
    state: "Yekaterinburg",
    country: "Russia",
    latitude: "56.8389",
    longitude: "60.6057",
  },
  {
    state: "Nizhny Novgorod",
    country: "Russia",
    latitude: "56.2965",
    longitude: "43.9361",
  },
  {
    state: "Kazan",
    country: "Russia",
    latitude: "55.8304",
    longitude: "49.0661",
  },
  {
    state: "Chelyabinsk",
    country: "Russia",
    latitude: "55.1644",
    longitude: "61.4368",
  },
  {
    state: "Omsk",
    country: "Russia",
    latitude: "54.9885",
    longitude: "73.3242",
  },
  {
    state: "Rostov-on-Don",
    country: "Russia",
    latitude: "47.2357",
    longitude: "39.7015",
  },
  {
    state: "Ufa",
    country: "Russia",
    latitude: "54.7388",
    longitude: "55.9721",
  },

  {
    state: "Ile-de-France",
    country: "France",
    latitude: "48.8566",
    longitude: "2.3522",
  },
  {
    state: "Provence-Alpes-Côte d'Azur",
    country: "France",
    latitude: "43.9352",
    longitude: "6.0679",
  },
  {
    state: "Auvergne-Rhône-Alpes",
    country: "France",
    latitude: "45.7640",
    longitude: "4.8357",
  },
  {
    state: "Nouvelle-Aquitaine",
    country: "France",
    latitude: "44.8378",
    longitude: "-0.5792",
  },
  {
    state: "Occitanie",
    country: "France",
    latitude: "43.6047",
    longitude: "1.4442",
  },
  {
    state: "Hauts-de-France",
    country: "France",
    latitude: "50.6292",
    longitude: "3.0573",
  },
  {
    state: "Grand Est",
    country: "France",
    latitude: "48.5734",
    longitude: "7.7521",
  },
  {
    state: "Brittany",
    country: "France",
    latitude: "48.2020",
    longitude: "-2.9326",
  },
  {
    state: "Normandy",
    country: "France",
    latitude: "49.1829",
    longitude: "-0.3707",
  },
  {
    state: "Pays de la Loire",
    country: "France",
    latitude: "47.2184",
    longitude: "-1.5536",
  },

  {
    state: "Beijing",
    country: "China",
    latitude: "39.9042",
    longitude: "116.4074",
  },
  {
    state: "Shanghai",
    country: "China",
    latitude: "31.2304",
    longitude: "121.4737",
  },
  {
    state: "Chongqing",
    country: "China",
    latitude: "29.4316",
    longitude: "106.9123",
  },
  {
    state: "Guangdong",
    country: "China",
    latitude: "23.3790",
    longitude: "113.7633",
  },
  {
    state: "Zhejiang",
    country: "China",
    latitude: "29.1832",
    longitude: "120.0934",
  },
  {
    state: "Sichuan",
    country: "China",
    latitude: "30.6516",
    longitude: "104.0759",
  },
  {
    state: "Hubei",
    country: "China",
    latitude: "30.5844",
    longitude: "114.2986",
  },
  {
    state: "Shandong",
    country: "China",
    latitude: "36.6683",
    longitude: "117.0208",
  },
  {
    state: "Henan",
    country: "China",
    latitude: "34.7655",
    longitude: "113.7536",
  },
  {
    state: "Jiangsu",
    country: "China",
    latitude: "32.0603",
    longitude: "118.7969",
  },

  {
    state: "Riyadh",
    country: "Saudi Arabia",
    latitude: "24.7136",
    longitude: "46.6753",
  },
  {
    state: "Mecca",
    country: "Saudi Arabia",
    latitude: "21.3891",
    longitude: "39.8579",
  },
  {
    state: "Medina",
    country: "Saudi Arabia",
    latitude: "24.5247",
    longitude: "39.5692",
  },
  {
    state: "Eastern Province",
    country: "Saudi Arabia",
    latitude: "26.4207",
    longitude: "50.0888",
  },
  {
    state: "Asir",
    country: "Saudi Arabia",
    latitude: "18.2164",
    longitude: "42.5053",
  },
  {
    state: "Tabuk",
    country: "Saudi Arabia",
    latitude: "28.3834",
    longitude: "36.5549",
  },
  {
    state: "Al-Qassim",
    country: "Saudi Arabia",
    latitude: "26.1949",
    longitude: "43.7783",
  },
  {
    state: "Hail",
    country: "Saudi Arabia",
    latitude: "27.5114",
    longitude: "41.7208",
  },
  {
    state: "Jazan",
    country: "Saudi Arabia",
    latitude: "16.8892",
    longitude: "42.5510",
  },
  {
    state: "Najran",
    country: "Saudi Arabia",
    latitude: "17.5660",
    longitude: "44.2236",
  },

  {
    state: "Dubai",
    country: "United Arab Emirates",
    latitude: "25.2760",
    longitude: "55.2962",
  },
  {
    state: "Abu Dhabi",
    country: "United Arab Emirates",
    latitude: "24.4539",
    longitude: "54.3773",
  },
  {
    state: "Sharjah",
    country: "United Arab Emirates",
    latitude: "25.3463",
    longitude: "55.4209",
  },
  {
    state: "Ajman",
    country: "United Arab Emirates",
    latitude: "25.4052",
    longitude: "55.5136",
  },
  {
    state: "Ras Al Khaimah",
    country: "United Arab Emirates",
    latitude: "25.8007",
    longitude: "55.9762",
  },
  {
    state: "Fujairah",
    country: "United Arab Emirates",
    latitude: "25.1288",
    longitude: "56.3265",
  },
  {
    state: "Umm Al Quwain",
    country: "United Arab Emirates",
    latitude: "25.5647",
    longitude: "55.5552",
  },
  {
    state: "Al Ain",
    country: "United Arab Emirates",
    latitude: "24.1302",
    longitude: "55.8023",
  },
  {
    state: "Khor Fakkan",
    country: "United Arab Emirates",
    latitude: "25.3312",
    longitude: "56.3423",
  },
  {
    state: "Dibba Al-Fujairah",
    country: "United Arab Emirates",
    latitude: "25.5919",
    longitude: "56.2618",
  },

  {
    state: "England",
    country: "United Kingdom",
    latitude: "51.509865",
    longitude: "-0.118092",
  },
  {
    state: "Scotland",
    country: "United Kingdom",
    latitude: "55.9533",
    longitude: "-3.1883",
  },
  {
    state: "Wales",
    country: "United Kingdom",
    latitude: "51.4816",
    longitude: "-3.1791",
  },
  {
    state: "Northern Ireland",
    country: "United Kingdom",
    latitude: "54.5973",
    longitude: "-5.9301",
  },
  {
    state: "West Midlands",
    country: "United Kingdom",
    latitude: "52.4862",
    longitude: "-1.8904",
  },
  {
    state: "Greater Manchester",
    country: "United Kingdom",
    latitude: "53.4808",
    longitude: "-2.2426",
  },
  {
    state: "Merseyside",
    country: "United Kingdom",
    latitude: "53.4084",
    longitude: "-2.9916",
  },
  {
    state: "South Yorkshire",
    country: "United Kingdom",
    latitude: "53.5526",
    longitude: "-1.4802",
  },
  {
    state: "Tyne and Wear",
    country: "United Kingdom",
    latitude: "54.9783",
    longitude: "-1.6174",
  },
  {
    state: "Hampshire",
    country: "United Kingdom",
    latitude: "51.0577",
    longitude: "-1.3080",
  },

  {
    state: "Tokyo",
    country: "Japan",
    latitude: "35.6895",
    longitude: "139.6917",
  },
  {
    state: "Osaka",
    country: "Japan",
    latitude: "34.6937",
    longitude: "135.5023",
  },
  {
    state: "Kyoto",
    country: "Japan",
    latitude: "35.0116",
    longitude: "135.7681",
  },
  {
    state: "Hokkaido",
    country: "Japan",
    latitude: "43.0642",
    longitude: "141.3469",
  },
  {
    state: "Fukuoka",
    country: "Japan",
    latitude: "33.5902",
    longitude: "130.4017",
  },
  {
    state: "Kanagawa",
    country: "Japan",
    latitude: "35.4477",
    longitude: "139.6425",
  },
  {
    state: "Saitama",
    country: "Japan",
    latitude: "35.8617",
    longitude: "139.6455",
  },
  {
    state: "Hiroshima",
    country: "Japan",
    latitude: "34.3853",
    longitude: "132.4553",
  },
  {
    state: "Aichi",
    country: "Japan",
    latitude: "35.1803",
    longitude: "136.9066",
  },
  {
    state: "Chiba",
    country: "Japan",
    latitude: "35.6073",
    longitude: "140.1063",
  },

  {
    state: "Seoul",
    country: "South Korea",
    latitude: "37.5665",
    longitude: "126.9780",
  },
  {
    state: "Busan",
    country: "South Korea",
    latitude: "35.1796",
    longitude: "129.0756",
  },
  {
    state: "Incheon",
    country: "South Korea",
    latitude: "37.4563",
    longitude: "126.7052",
  },
  {
    state: "Daegu",
    country: "South Korea",
    latitude: "35.8714",
    longitude: "128.6014",
  },
  {
    state: "Daejeon",
    country: "South Korea",
    latitude: "36.3504",
    longitude: "127.3845",
  },
  {
    state: "Gwangju",
    country: "South Korea",
    latitude: "35.1595",
    longitude: "126.8526",
  },
  {
    state: "Ulsan",
    country: "South Korea",
    latitude: "35.5384",
    longitude: "129.3114",
  },
  {
    state: "Suwon",
    country: "South Korea",
    latitude: "37.2636",
    longitude: "127.0286",
  },
  {
    state: "Changwon",
    country: "South Korea",
    latitude: "35.2285",
    longitude: "128.6811",
  },
  {
    state: "Seongnam",
    country: "South Korea",
    latitude: "37.4204",
    longitude: "127.1261",
  },

  {
    state: "Mexico City",
    country: "Mexico",
    latitude: "19.4326",
    longitude: "-99.1332",
  },
  {
    state: "Guadalajara",
    country: "Mexico",
    latitude: "20.6597",
    longitude: "-103.3496",
  },
  {
    state: "Monterrey",
    country: "Mexico",
    latitude: "25.6866",
    longitude: "-100.3161",
  },
  {
    state: "Puebla",
    country: "Mexico",
    latitude: "19.0414",
    longitude: "-98.2063",
  },
  {
    state: "Tijuana",
    country: "Mexico",
    latitude: "32.5149",
    longitude: "-117.0382",
  },
  {
    state: "León",
    country: "Mexico",
    latitude: "21.1619",
    longitude: "-101.6868",
  },
  {
    state: "Cancún",
    country: "Mexico",
    latitude: "21.1619",
    longitude: "-86.8515",
  },
  {
    state: "Mérida",
    country: "Mexico",
    latitude: "20.9674",
    longitude: "-89.5926",
  },
  {
    state: "Ciudad Juárez",
    country: "Mexico",
    latitude: "31.6904",
    longitude: "-106.4245",
  },
  {
    state: "Querétaro",
    country: "Mexico",
    latitude: "20.5888",
    longitude: "-100.3899",
  },
  {
    state: "Istanbul",
    country: "Turkey",
    latitude: "41.0082",
    longitude: "28.9784",
  },
  {
    state: "Ankara",
    country: "Turkey",
    latitude: "39.9334",
    longitude: "32.8597",
  },
  {
    state: "Izmir",
    country: "Turkey",
    latitude: "38.4192",
    longitude: "27.1287",
  },
  {
    state: "Antalya",
    country: "Turkey",
    latitude: "36.8969",
    longitude: "30.7133",
  },
  {
    state: "Bursa",
    country: "Turkey",
    latitude: "40.1824",
    longitude: "29.0671",
  },
  {
    state: "Adana",
    country: "Turkey",
    latitude: "37.0004",
    longitude: "35.3213",
  },
  {
    state: "Konya",
    country: "Turkey",
    latitude: "37.8713",
    longitude: "32.5034",
  },
  {
    state: "Kabul",
    country: "Afghanistan",
    latitude: "34.5553",
    longitude: "69.2075",
  },
];
